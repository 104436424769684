import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Breadcrumb from "../../components/breadcrumb"

const NosFormationsPage = ({ data }) => (
  <Layout>
    <Seo title="Conduite Accompagnée (AAC)" breadcrumb={[
      { url: "nos-formations", name: "Nos Formations"},
      { url: "conduite-accompagnee", name: "Conduite Accompagnée (AAC)"}
    ]}  />
    <Breadcrumb paths={[{url: "/nos-formations/", name:"Nos formations"}, {url: null, name: "Conduite Accompagnée"}]} />
    <h1 className="h1">Conduite Accompagnée (AAC)</h1>
    <div
      className="text md:w-3/4 html-content"
      dangerouslySetInnerHTML={{ __html: data.strapiNosFormations.conduite_accompagnee_page }}
    ></div>
  </Layout>
)

export default NosFormationsPage

export const pageQuery = graphql`
  query ConduiteAccompagneeQuery {
    strapiNosFormations {
      conduite_accompagnee_page
    }
  }
`
